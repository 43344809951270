<form clrForm [formGroup]="formFilter">
  <table>
    <tr>
      <td>
        <clr-input-container id="inputContainerInput">
          <label for="inputContainerInput">Filter:</label>
          <input
            style="width: 100%"
            clrInput
            [formControlName]="'filter'"
            (ngModelChange)="inputChanged($event)"
          />
          <button class="btn btn-outline">clear</button>
        </clr-input-container>
      </td>
      <td>
        <button
          [disabled]="search.length <= 0"
          (click)="onResetClick()"
          class="btn btn-outline"
        >
          Reset
        </button>
      </td>
    </tr>
  </table>
</form>
