<clr-modal
  [clrModalOpen]="true"
  [clrModalClosable]="true"
  (clrModalOpenChange)="openChange($event)"
  [clrModalSize]="'lg'"
>
  <!-- [clrModalOpen]="true"
[clrModalClosable]="true" -->
  <!-- <h3 class="modal-title">
    <cds-icon shape="host" size="md"></cds-icon> File-Import
  </h3> -->
  <div class="modal-body">
    <!-- <span class="close cursor" onclick="closeModal()">&times;</span> -->
    <div class="modal-content">
      <div class="slideshow-container">
        <!-- <ng-container *ngFor="let img of images"> -->
        <div class="mySlides fade" *ngFor="let img of images">
          <div class="numbertext">
            {{ images.indexOf(img) + 1 }} / {{ images.length }}
          </div>
          <img [src]="imgViewSrc" [alt]="img.Original" style="width: 100%" />
          <!-- <div class="text">{{ img.Original }}</div> -->
        </div>
        <!-- </ng-container> -->

        <!-- Next/previous controls -->
        <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
        <a class="next" (click)="plusSlides(1)">&#10095;</a>
      </div>
      <!-- Caption text -->
      <div class="caption-container">
        <!-- <p id="caption"></p> -->
        <div class="text">{{ currentTxt }}</div>
      </div>

      <!-- Thumbnail image controls -->
      <!-- <ng-container *ngFor="let img of images"> -->
      <div
        style="
          display: inline-block;
          text-align: center;
          justify-content: center;
          vertical-align: middle;
        "
      >
        <div class="column" *ngFor="let img of images">
          <img
            class="demo"
            [src]="getThumbImage(img)"
            (click)="currentSlide(index(img))"
            [alt]="img.Original"
          />
        </div>
      </div>
      <!-- </ng-container> -->
    </div>
  </div>
</clr-modal>
