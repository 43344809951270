<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <div class="card-header">
        <clr-breadcrumb></clr-breadcrumb>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <!-- <h3 class="card-header">Header</h3> -->
  <div class="card-block">
    <h6 class="card-title">Anlage</h6>
    <!-- <app-risks-cabinet-table [cabinet$]="cabinet$" /> -->
    <app-risks-cabinet-table [cabinet]="cabinet$ | async" />
    <h6 class="card-titel">Bewertungen</h6>
    <div class="card-text">
      <app-risks-evaluations-overview
        #auswertungenTab
        [cabinet]="cabinet$ | async"
      ></app-risks-evaluations-overview>
    </div>
  </div>
  <!-- <div class="card-footer">Footer</div> -->
</div>
