<br />

<p>
  <button
    class="btn btn-outline"
    [disabled]="disabled || addBtnDisabled"
    (click)="onAddFileClick()"
  >
    <cds-icon shape="plus-circle"></cds-icon> hinzufügen
  </button>
  {{ infoTxt }}
</p>

<div>
  <table class="table">
    <thead>
      <tr>
        <th>Vorschau</th>
        <th class="left">Name</th>
        <th>Kategorie</th>
        <th>Optionen</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let img of evaluation?.Images; let i = index">
        <td>
          <img
            [src]="getThumbImage(img)"
            width="40px"
            alt="Vorschau"
            role="button"
            (click)="showImage(img)"
          />
        </td>
        <td class="left">
          <a [routerLink]="[]" role="button" (click)="showImage(img)">
            {{ img.Name }}
          </a>
        </td>
        <td>
          <ng-container *ngIf="template$ | async as template">
            <clr-dropdown [clrCloseMenuOnItemClick]="true">
              <button class="dropdown-toggle btn btn-link" clrDropdownTrigger>
                <label>Kategorie</label>
                <cds-icon shape="angle" direction="down"></cds-icon>
              </button>
              <clr-dropdown-menu clrPosition="top-right" *clrIfOpen>
                <clr-dropdown *ngFor="let catItm of categories">
                  <button clrDropdownTrigger>
                    {{ catItm.Category }}
                  </button>
                  <clr-dropdown-menu clrDropdownItem>
                    <clr-dropdown *ngFor="let element of catItm.Items">
                      <button
                        clrDropdownItem
                        (click)="setCategory(img, element)"
                      >
                        <cds-icon
                          shape="check"
                          *ngIf="isCategorySet(img, element)"
                        ></cds-icon>
                        <label>{{ element.Gefaehrdung }}</label>
                      </button>
                    </clr-dropdown>
                  </clr-dropdown-menu>
                </clr-dropdown>
              </clr-dropdown-menu>
            </clr-dropdown>
          </ng-container>
        </td>
        <td>
          &nbsp;<button
            class="btn btn-icon btn-primary"
            title="löschen"
            [disabled]="disabled"
            (click)="onDeleteFile(img)"
          >
            <cds-icon shape="trash" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- dialogs -->

<app-dialog-add-image
  #imgDialog
  *ngIf="dialogAddFile"
  (isDialogOpen)="onDialogAddFileClosing()"
  (uploadFiles)="onUpdateFiles($event)"
  (initializied)="setDialog($event)"
  [maxCount]="getDialogImgCount()"
></app-dialog-add-image>

<app-dialog-show-image
  #imgDialog
  [images]="getImages()"
  (modalOpen)="onDialogClosing($event)"
  [slideIndex]="imgIndex"
  *ngIf="dialogViewImg"
/>

<clr-modal [(clrModalOpen)]="dialogViewOptions" clrModalSize="xl">
  <div class="modal-body">
    <clr-dropdown [clrCloseMenuOnItemClick]="true">
      <button class="dropdown-toggle btn btn-link" clrDropdownTrigger>
        <label>Kategorie</label>
        <cds-icon shape="angle" direction="down"></cds-icon>
      </button>
      <clr-dropdown-menu clrPosition="top-left" *clrIfOpen>
        <label class="dropdown-header" aria-hidden="true"
          >Dropdown header</label
        >
        <div aria-label="Dropdown header Action 1" clrDropdownItem>
          Action 1
        </div>
        <div
          aria-label="Dropdown header Disabled Action"
          [clrDisabled]="true"
          clrDropdownItem
        >
          Disabled Action
        </div>
        <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
        <!-- <div clrDropdownItem>-test 1-</div>
        <div clrDropdownItem>-test 2-</div>
        <div clrDropdownItem>-test 3-</div> -->
      </clr-dropdown-menu>
      <!-- <ng-container *ngFor="let catItm of categories$ | async">
        <clr-dropdown clrPosition="bottom-left" *clrIfOpen>
          <button clrDropdownTrigger *ngFor="let element of catItm.Items">
            {{ catItm.Category }}
          </button>
          <clr-dropdown-menu
            aria-label="Dropdown1"
            clrDropdownItem
            *ngFor="let element of catItm.Items"
          >
            <div>{{ element }}</div>
          </clr-dropdown-menu>
        </clr-dropdown>
      </ng-container> -->
    </clr-dropdown>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="dialogViewOptions = false"
    >
      Ok
    </button>
  </div>
</clr-modal>
