import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Cabinet } from 'src/app/models/Cabinet';
import { Evaluation } from 'src/app/models/Evaluation';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import { Observable } from 'rxjs';
import { selectProfilRoles } from '../../users/+profil/profil.selectors';
import { Template } from 'src/app/models/Template';

@Component({
  selector: 'app-risks-evaluations-overview',
  templateUrl: './risks-evaluations-overview.component.html',
  styleUrls: ['./risks-evaluations-overview.component.css'],
})
export class RiskEvaluationComponent implements OnInit, OnChanges {
  @Input() cabinet: Cabinet | null = null;
  evaluations$ = undefined as unknown as Observable<Evaluation[]>;
  template$ = this.service.loadTemplate();
  template: Template | undefined = undefined;
  selected: Evaluation | undefined = undefined;
  selectedEvaluation = undefined as unknown as Evaluation;
  extended = false;
  pdfShown = false;
  onHelper = false;
  onDelete = false;
  onNewEvaluation = false;
  pdfBase64Src = null as any;
  title = '';
  message = '';
  infoTxt = '';
  isService$ = this.store.select(selectProfilRoles(['service']));
  isManager$ = this.store.select(selectProfilRoles(['admin']));
  isService = false;
  isManager = false;
  online = this.service.isOnline$;

  constructor(
    private store: Store,
    private service: AssessmentStoreService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    if (this.cabinet && this.cabinet?._id) {
      this.evaluations$ = this.service.loadEvaluationsByCabinet(
        this.cabinet._id,
      );
    }
    this.template$.subscribe((t) => (this.template = t));
  }

  ngOnInit() {
    if (this.cabinet && this.cabinet?._id) {
      this.evaluations$ = this.service.loadEvaluationsByCabinet(
        this.cabinet._id,
      );
    }
    this.isManager$.subscribe((m) => (this.isManager = m));
  }

  getInfo(): string {
    if (this.selected?.PDFResult?.requested) {
      return 'Bearbeitung angefordert';
    }
    return '';
  }

  editEvaluationClick() {
    const id = this.selected?._id;
    if (id) {
      this.router.navigate(['/risks/editevaluation', id], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  deleteEvaluationClick() {
    const id = this.selected?._id;
    if (id && this.cabinet) {
      console.log(`Eintrag [${id}] wird gelöscht!`);
      this.evaluations$ = this.service.deleteEvaluation(this.cabinet._id, id);
      this.onDelete = false;
    }
  }

  newEvaluationClick() {
    if (this.cabinet?._id?.startsWith('offline')) return;
    if (this.template && this.cabinet) {
      this.service
        .loadNewEvaluationsFor(this.cabinet._id, this.template._id)
        .subscribe((e) => {
          if (!e) {
            console.error('evaluation:', e);
            return;
          }
          this.router.navigate(['/risks/editevaluation', e._id], {
            relativeTo: this.activatedRoute,
          });
        });
    } else if (this.cabinet) {
      console.error('Template was `undefined` will be loaded.');
      this.service.loadTemplate().subscribe((t) => {
        this.service
          .loadNewEvaluationsFor(this.cabinet?._id, t._id)
          .subscribe((e) => {
            if (!e) {
              console.error('evaluation:', e);
              return;
            }
            this.router.navigate(['/risks/editevaluation', e._id], {
              relativeTo: this.activatedRoute,
            });
          });
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cabinet']) {
      this.cabinet = changes['cabinet'].currentValue as Cabinet;
      if (this.cabinet && this.cabinet?._id) {
        this.evaluations$ = this.service.loadEvaluationsByCabinet(
          this.cabinet._id,
        );
      }
    }
  }

  isOfflineCabinet(): boolean {
    if (this.cabinet?._id?.startsWith('offline') && !this.online.value)
      return true;
    return false;
  }

  forEvaluationDisabled(): boolean {
    if (this.selected) {
      this.selectedEvaluation = this.selected;
      return this.selectedEvaluation?.PDFResult?.File != undefined;
    } else {
      this.selectedEvaluation = undefined as unknown as Evaluation;
    }
    return true;
  }

  onNewPdfResultClick() {
    this.pdfShown = false;
    this.extended = true;
    if (this.selectedEvaluation) {
      this.service
        .loadNewPdfFor(this.selectedEvaluation._id)
        .subscribe((result) => {
          this.pdfBase64Src = result?.data; //.toString('base64');
          this.pdfShown = true;
        });
    }
  }

  onHelperClick(title: string, message: string | undefined) {
    this.title = title || '';
    this.message = message || '';
    this.onHelper = true;
    return false;
  }

  showPdf(evaluation: Evaluation) {
    this.pdfShown = false;
    this.extended = true;
    this.service.getPdfEvaluation(evaluation._id).subscribe((result) => {
      this.pdfBase64Src = result?.data; // .toString('base64');
      this.pdfShown = true;
    });
  }
}
