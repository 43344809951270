import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Cabinet } from 'src/app/models/Cabinet';
import { AuthService } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';
import {
  Evaluation,
  AssesmentItem,
  PruefItem,
} from 'src/app/models/Evaluation';
import { AssessmentStoreService } from 'src/app/services/assessment.service';

@Component({
  selector: 'app-risks-tab-results',
  templateUrl: './risks-tab-results.component.html',
  styleUrls: ['./risks-tab-results.component.css'],
})
export class RiskTabResultsComponent implements OnInit, OnChanges {
  cabinet = {} as Cabinet;
  @Input() evaluation = {} as Evaluation;
  assesmentItems = [] as AssesmentItem[];
  pruefItems = [] as PruefItem[];
  @Input() tabFor$ = '';
  @Input() evaluation$ = new Observable<Evaluation>(); //new Observable<CabinetEvaluation>() | undefined;
  @Input() evaluations$ = undefined as unknown as Observable<[Evaluation]>; //new Observable<CabinetEvaluation>() | undefined;
  @Output() updated = new EventEmitter();
  @Output() init = new EventEmitter();

  setEvaluation(evaluation: Evaluation) {
    // evaluations.forEach((cabinetEvaluation) => {
    console.log(`setEvaluation(${evaluation._id})`, evaluation);
    this.evaluation = evaluation;
    const tmpAssesmentItems = [] as AssesmentItem[];
    // const tmpEvaluationItems = [] as EvaluationItem[];
    const tpmPruefItems = [] as PruefItem[];
    this.evaluation?.Page1?.Assesment?.forEach((assesmentItem) => {
      assesmentItem.AssesmentItem.forEach((x: any) => {
        tmpAssesmentItems.push(x);
      });
    });
    this.assesmentItems = tmpAssesmentItems;

    this.evaluation?.Page2?.PruefItems?.forEach((x) => {
      tpmPruefItems.push(x);
    });
    this.pruefItems = tpmPruefItems;
  }

  onHelper = false;

  pdfView = false;
  test = false;
  pdfBase64Src: any;

  title = '';
  message = '';
  showAuswahl = false;
  result = {} as {
    SummeBewertung: number;
    SchlechtesteBewertung: number;
    Reduktionsfaktor: number;
    Kurzschlusstrom: number;
  };

  // private formBuilder = inject(FormBuilder);

  // formDynamisch = this.formBuilder.group({});

  constructor(
    private store: Store,
    private riskService: AssessmentStoreService,
  ) {
    // this.cabinet$.subscribe((cabinet) => {
    //   console.log('constructor() cabinet:', cabinet);
    //   this.cabinet = cabinet;
    //   // this.store.dispatch()
    //   this.store.dispatch(
    //     EvaluationActions.loadEvaluation({
    //       cabinetId: cabinet._id,
    //     }),
    //   );
    //   // this.evaluation$ = this.store.select(
    //   //   selectCabinetEvaluation(this.cabinet._id),
    //   // );
    //   this.evaluation$.subscribe((cabinetEvaluation) => {
    //     console.log('constructor() cabinetEvaluation)', cabinetEvaluation);
    //     cabinetEvaluation.Page1.Assesment;
    //     // TODO
    //   });
    //   this.init.emit(this);
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges()', changes);
    // TODO
    // if (changes['contracts']) {
    //   // this.updateItems();
    // }
  }

  onInput(event: any) {
    console.log(event);
    // event.preventDefault();
    return false;
  }

  dateChanged($event: any, item: AssesmentItem) {
    try {
      const obj = this.assesmentItems.find((i) => i.Nr === item.Nr);

      const date = $event?.srcElement?.value
        ? new Date($event.srcElement.value)
        : new Date();

      item.UmsetzenBis = date;
      if (obj !== undefined) obj.UmsetzenBis = date;
    } catch (err) {
      console.log(err);
    }
    console.log($event);
  }

  ngOnInit() {
    this.init.emit(this);
    if (this.evaluation) {
      console.log('EVALUATION:', this.evaluation);
      const tmpAssesmentItems = [] as AssesmentItem[];
      const tpmPruefItems = [] as PruefItem[];
      this.evaluation?.Page1?.Assesment?.forEach((assesmentItem) => {
        assesmentItem.AssesmentItem.forEach((x: any) => {
          tmpAssesmentItems.push(x);
        });
      });
      this.assesmentItems = tmpAssesmentItems;

      this.evaluation?.Page2?.PruefItems?.forEach((x) => {
        tpmPruefItems.push(x);
      });
      this.pruefItems = tpmPruefItems;
    }

    this.evaluation$?.subscribe((cabinetEvaluation) => {
      this.evaluation = cabinetEvaluation;
      const tmpAssesmentItems = [] as AssesmentItem[];
      const tpmPruefItems = [] as PruefItem[];
      cabinetEvaluation?.Page1?.Assesment?.forEach((assesmentItem) => {
        assesmentItem.AssesmentItem.forEach((x: any) => {
          tmpAssesmentItems.push(x);
        });
      });
      this.assesmentItems = tmpAssesmentItems;

      cabinetEvaluation?.Page2?.PruefItems?.forEach((x) => {
        tpmPruefItems.push(x);
      });
      this.pruefItems = tpmPruefItems;
    });
  }

  onHelperClick(title: string, message: string | undefined) {
    this.title = title || '';
    this.message = message || '';

    this.onHelper = true;
    return false;
  }

  onPdfDocumentClick() {
    this.test = false;
    this.pdfView = true;

    this.riskService
      .getPdfEvaluationsFor(this.cabinet._id)
      .subscribe((result) => {
        this.pdfBase64Src = result.data; //.toString('base64');
        this.test = true;
      });
  }

  checkboxchange(item: AssesmentItem) {
    try {
      const obj = this.assesmentItems.find((i) => i.Nr === item.Nr);
      if (item.UmsetzungErfolgt == true) {
        if (obj !== undefined) {
          obj.UmsetzungErfolgt = false;
        }
      } else {
        if (obj !== undefined) {
          obj.UmsetzungErfolgt = true;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  getRisikoNachMassnahme(item: AssesmentItem): {
    style: string;
    text: string;
  } {
    if (item.UmsetzungErfolgt) {
      return this.getStyle(
        item.SchadensmassNachMassnahme,
        item.EintrittwahrscheinlichkeitNachMassnahme,
      );
    } else {
      return this.getStyle(item.Schadensmass, item.Eintrittwahrscheinlichkeit);
    }
  }

  getRisiko(item: AssesmentItem): {
    style: string;
    text: string;
  } {
    return this.getStyle(item.Schadensmass, item.Eintrittwahrscheinlichkeit);
  }

  getStyle(
    Schadensmass: string,
    Eintrittwahrscheinlichkeit: string,
  ): { style: string; text: string } {
    const wert1 = Schadensmass?.trim()?.toLowerCase();
    const wert2 = Eintrittwahrscheinlichkeit?.trim()?.toLowerCase();
    switch (true) {
      case wert1 == 'sehr leicht' && wert2 == 'sehr selten':
      case wert1 == 'sehr leicht' && wert2 == 'gelegentlich':
      case wert1 == 'sehr leicht' && wert2 == 'oft':
      case wert1 == 'leicht' && wert2 == 'sehr selten':
      case wert1 == 'leicht' && wert2 == 'gelegentlich':
      case wert1 == 'mittel' && wert2 == 'sehr selten':
      case wert1 == 'schwer' && wert2 == 'sehr selten':
        return {
          style: 'backgroundColor: green; color: white;',
          text: 'Kein unmittelbarer Handlungsbedarf',
        }; // 'green';
      case wert1 == 'sehr leicht' && wert2 == 'häufig':
      case wert1 == 'leicht' && wert2 == 'oft':
      case wert1 == 'leicht' && wert2 == 'häufig':
      case wert1 == 'mittel' && wert2 == 'gelegentlich':
      case wert1 == 'mittel' && wert2 == 'oft':
      case wert1 == 'schwer' && wert2 == 'gelegentlich':
      case wert1 == 'sehr schwer' && wert2 == 'sehr selten':
        return {
          style: 'backgroundColor: yellow; color: black;',
          text: 'Mittelfristiger Handlungsbedarf',
        }; //'yellow';
      case wert1 == 'sehr leicht' && wert2 == 'Sehr selten':
      case wert1 == 'leicht' && wert2 == 'ständig':
      case wert1 == 'mittel' && wert2 == 'häufig':
      case wert1 == 'mittel' && wert2 == 'ständig':
      case wert1 == 'schwer' && wert2 == 'oft':
      case wert1 == 'schwer' && wert2 == 'häufig':
      case wert1 == 'schwer' && wert2 == 'ständig':
      case wert1 == 'sehr schwer' && wert2 == 'gelegentlich':
      case wert1 == 'sehr schwer' && wert2 == 'oft':
      case wert1 == 'sehr schwer' && wert2 == 'häufig':
      case wert1 == 'sehr schwer' && wert2 == 'ständig':
        return {
          style: 'backgroundColor: red; color: white;',
          text: 'Akuter Handlungsbedarf',
        }; // 'red';
      default:
        return {
          style: 'backgroundColor: red; color: white;',
          text: 'Akuter Handlungsbedarf',
        }; // 'red';
    }
  }
}
