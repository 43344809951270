import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Cabinet } from 'src/app/models/Cabinet';
import { Template } from 'src/app/models/Template';
import { RiskTabResultsComponent } from '../risks-tab-results/risks-tab-results.component';
import { Evaluation, EvaluationCalculations } from 'src/app/models/Evaluation';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import {
  ClrBreadcrumbModel,
  ClrBreadcrumbService,
} from '@porscheinformatik/clr-addons';

@Component({
  selector: 'app-risks-review',
  templateUrl: './risks-review.component.html',
  styleUrls: ['./risks-review.component.css'],
})
export class RisksReviewComponent implements OnInit {
  cabinet$: Observable<Cabinet> | null = null;
  evaluationCalculations$ = {} as EvaluationCalculations;
  template$ = new Observable<Template>();
  loading$ = new Observable<boolean>();
  error$ = new Observable<HttpErrorResponse | null>();
  evaluations$ = new Observable<Evaluation[]>();
  evaluation$ = undefined as unknown as Observable<Evaluation>;

  evaluation = undefined as unknown as Evaluation;

  @ViewChild('Auswertung') tabAuswertung = {} as RiskTabResultsComponent;

  constructor(
    private store: Store,
    private service: AssessmentStoreService,
    private route: ActivatedRoute,
    private breadcrumbService: ClrBreadcrumbService,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params['id']) {
        console.error('ngOnInit() => params[id]:', params['id']);
        this.cabinet$ = this.service.loadCabinet(params['id']);
        this.cabinet$.subscribe((c) => {
          const breadcrumb1: ClrBreadcrumbModel = { label: 'Home', url: '/' };
          const breadcrumb2: ClrBreadcrumbModel = {
            label: 'Gefährdungsbeurteilung',
            url: '/risks',
          };
          const breadcrumb3: ClrBreadcrumbModel = {
            label: 'Anlage',
          };
          this.breadcrumbService.updateBreadcrumb([
            breadcrumb1,
            breadcrumb2,
            breadcrumb3,
          ]);
        });
      }
    });
  }

  initAuswertung($event: any) {
    try {
      this.tabAuswertung = $event as RiskTabResultsComponent;
      if (this.evaluation) {
        this.tabAuswertung.setEvaluation(this.evaluation);
      }
    } catch (err) {
      console.error(err);
    }
  }
}
