import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ClrButton } from '@clr/angular';
import { Subscription } from 'rxjs';

export interface CheckObj {
  file: File;
  idx: number;
  filescount: number;
}

interface PrivCheckObj {
  idx: number;
  uploaded: boolean;
  response: boolean;
}

@Component({
  selector: 'app-dialog-add-image',
  templateUrl: './dialog-add-image.component.html',
  styleUrls: ['./dialog-add-image.component.css'],
})
export class DialogAddImageComponent implements OnInit {
  @Input() maxCount = 5;
  @Output() isDialogOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updated: EventEmitter<CheckObj> = new EventEmitter<CheckObj>();
  @Output() uploadFiles: EventEmitter<CheckObj[]> = new EventEmitter<
    CheckObj[]
  >();
  @Output() initializied: EventEmitter<DialogAddImageComponent> =
    new EventEmitter<DialogAddImageComponent>();

  @ViewChild('sendBtn') sendBtn = {} as ClrButton;
  files: File[] = [];
  checkList: PrivCheckObj[] = [];
  fileCount = 0;
  step = 0;
  uploadProgress = 0;
  uploadSub$ = new Subscription();

  private formBuilder = inject(FormBuilder);

  formMobile = this.formBuilder.group({
    Image: this.formBuilder.group({}),
  });

  ngOnInit(): void {
    this.initializied.emit(this);

    // this.formMobile.addControl('Image', this.formBuilder.group({}));
  }

  onFilesSelected(event: any, sendBtn: any) {
    this.sendBtn = sendBtn;
    this.files = event.target.files;
    console.log('onFilesSelected() files', this.files?.length);
    this.fileCount = this.files.length;
    if (this.fileCount > this.maxCount) {
      alert(
        `Es kann maximal noch ${this.maxCount} Bild(er) hinzugefügt werden.`,
      );
      if (this.sendBtn) {
        this.sendBtn.disabled = true;
      }
      return;
    }
    this.sendBtn.disabled = false;
    this.checkList = [];
    for (let i = 0; i < this.fileCount; i += 1) {
      this.checkList.push({
        idx: i,
        uploaded: false,
        response: false,
      } as PrivCheckObj);
    }
  }

  public async fileSend(succes: boolean, idx: number): Promise<void> {
    try {
      const checker = this.checkList.find((c) => c.idx == idx);
      if (checker) {
        checker.uploaded = succes;
        checker.response = true;
      } else {
        console.warn(`idx[${idx}] not found in checklist:`, this.checkList);
        return;
      }
      const done = this.checkList.filter((c) => c.response).length;
      this.uploadProgress = done * this.step;
      if (this.checkList.length == done) {
        this.closeDialog();
        this.uploadProgress = 0;
      }
      console.log(`fileSend(${idx})    ${done} / ${this.checkList.length}`);
    } catch (err) {
      console.error('DIALOG `fileSend()` Error:', err);
    }
  }

  onSubmitFile() {
    if (this.files) {
      this.fileCount = this.files.length;
      this.step = 100 / this.fileCount;
      const updateFiles: CheckObj[] = [];
      for (let i = 0; i < this.fileCount; i += 1) {
        const resFile = {
          file: this.files[i],
          idx: i,
          filescount: this.fileCount,
        } as CheckObj;
        updateFiles.push(resFile);
        // this.updated.emit(resFile);
      }
      this.uploadFiles.emit(updateFiles);
    }
  }

  closeDialog() {
    this.isDialogOpen.emit(false);
  }
}
