import { Component, EventEmitter, inject, Input } from '@angular/core';
import { Cabinet } from 'src/app/models/Cabinet';
import { FormBuilder, FormControl } from '@angular/forms';
import { ClrDatagridFilterInterface, ClrDatagridFilter } from '@clr/angular';

@Component({
  selector: 'app-risk-list-filter',
  templateUrl: './risk-list-filter.component.html',
  styleUrls: ['./risk-list-filter.component.css'],
})
export class RiskListFilterComponent
  implements ClrDatagridFilterInterface<Cabinet>
{
  @Input() id = '';
  search = '';
  changes = new EventEmitter<any>(false);
  private formBuilder = inject(FormBuilder);

  formFilter = this.formBuilder.group({
    filter: new FormControl('', { nonNullable: true }),
  });

  constructor(private filterContainer: ClrDatagridFilter) {
    filterContainer.setFilter(
      this as unknown as ClrDatagridFilterInterface<Cabinet>,
    );
  }

  isActive(): boolean {
    return this.search.length > 0;
  }

  accepts(a: Cabinet): boolean {
    let result = false;
    const aIdxItm = a?.Anlage?.find(
      (itm: { ItemId: any }) => itm?.ItemId == this.id,
    ) as {
      ItemId: string;
      Value: object;
    };
    if (aIdxItm == undefined) {
      console.warn(`filter [${this.search}] for [${this.id}] is undefined`);
    } else if (
      (aIdxItm?.Value?.toString() as string)
        .toLowerCase()
        .indexOf(this.search) >= 0
    ) {
      result = true;
    }
    return result;
  }

  setItemId(id: string) {
    this.id = id;
  }

  inputChanged(event: any) {
    // console.log('Filter-Event:', event);
    this.search = `${event}`.toLowerCase();
    this.changes.emit(true);
  }

  onResetClick() {
    this.search = '';
    const control = this.formFilter.get<string>('filter');
    console.log('Filter Control:', control);
    if (control) control.setValue('');
  }
}
