<p></p>

<!-- <form clrForm [formGroup]="formDynamisch"> -->
<ng-container *ngIf="evaluation">
  <!-- ------------------------------- -->
  <ng-container
    *ngIf="evaluation?.Page1?.Assesment?.length !== undefined"
    id="inputContainer"
  >
    <button class="btn btn-outline" (click)="onPdfDocumentClick()">
      als PDF-Dokument
    </button>
    <br />
    <br />
    <div class="appitem"></div>
    <br />

    <clr-tabs>
      <ng-container *ngIf="assesmentItems.length > 0" id="auswertungen">
        <clr-tab>
          <button clrTabLink>Gefährdungsbeurteilung</button>
          <clr-tab-content *clrIfActive>
            <clr-datagrid>
              <clr-dg-placeholder
                >Wir konnten keine Einträge finden!</clr-dg-placeholder
              >
              <clr-dg-column [clrDgField]="'Nr'">Nr.</clr-dg-column>
              <clr-dg-column [clrDgField]="'Gefaehrdung'">
                Gefährdung
              </clr-dg-column>
              <clr-dg-column [clrDgField]="'Schadensmass'">
                Schadensausmaß
              </clr-dg-column>
              <clr-dg-column [clrDgField]="'Eintrittwahrscheinlichkeit'">
                Eintrittwahrscheinlichkeit
              </clr-dg-column>
              <clr-dg-column [clrDgField]="'Risikobewertung'">
                Risikobewertung
              </clr-dg-column>
              <clr-dg-column [clrDgField]="'Anlage'">Anlage</clr-dg-column>
              <clr-dg-row *clrDgItems="let item of assesmentItems">
                <clr-dg-cell>{{ item.Nr }}</clr-dg-cell>
                <clr-dg-cell>{{ item.Gefaehrdung }}</clr-dg-cell>
                <clr-dg-cell>{{ item.Schadensmass }}</clr-dg-cell>
                <clr-dg-cell>{{ item.Eintrittwahrscheinlichkeit }}</clr-dg-cell>
                <clr-dg-cell [style]="getRisiko(item).style">{{
                  getRisiko(item).text
                }}</clr-dg-cell>
                <clr-dg-cell>{{ item.Anlage }}</clr-dg-cell>
              </clr-dg-row>
            </clr-datagrid>
          </clr-tab-content>
        </clr-tab>
        <clr-tab>
          <button clrTabLink>
            Schutzmaßnahmen und Gefährdungsbeurteilung nach Umsetzung der
            Schutzmaßnahmen
          </button>
          <clr-tab-content *clrIfActive>
            <clr-datagrid>
              <clr-dg-placeholder
                >Wir konnten keine Einträge finden!</clr-dg-placeholder
              >
              <clr-dg-column [clrDgField]="'Nr'">Nr</clr-dg-column>
              <!-- <clr-dg-column [clrDgField]="'Massnahmen'">
                Maßnahmen
              </clr-dg-column> -->
              <clr-dg-column [clrDgField]="'MethodeTOP'">
                Methode (T-O-P)
              </clr-dg-column>
              <clr-dg-column [clrDgField]="'UmsetzenBis'">
                Umsetzung bis
              </clr-dg-column>
              <clr-dg-column [clrDgField]="'UmsetzungErfolgt'">
                Umsetzung
              </clr-dg-column>
              <clr-dg-column [clrDgField]="'NameUnterschrift'">
                Name
              </clr-dg-column>
              <clr-dg-column [clrDgField]="'SchadensmassNachMassnahme'">
                Schadens-ausmaß
              </clr-dg-column>
              <clr-dg-column
                [clrDgField]="'EintrittwahrscheinlichkeitNachMassnahme'"
              >
                Eintrittwahrscheinlichkeit
              </clr-dg-column>
              <clr-dg-column [clrDgField]="'RisikobewertungNachMassnahme'">
                Risikobewertung
              </clr-dg-column>

              <clr-dg-row *clrDgItems="let item of assesmentItems">
                <clr-dg-row-detail *clrIfExpanded [clrDgReplace]="false">
                  {{ item.Massnahmen }}
                </clr-dg-row-detail>

                <clr-dg-cell>{{ item.Nr }}</clr-dg-cell>
                <!-- <clr-dg-cell>{{ item.Massnahmen }}</clr-dg-cell> -->
                <clr-dg-cell>{{ item.MethodeTOP }}</clr-dg-cell>
                <clr-dg-cell>
                  <clr-input-container id="inputDatesContainer">
                    <input
                      clrInput
                      type="date"
                      (input)="dateChanged($event, item)"
                      style="width: 100%"
                      class="clr-col-12 clr-col-md-9"
                      id="d{{ item.Nr }}"
                      [value]="item.UmsetzenBis | date: 'yyyy-MM-dd'"
                      name="UmsetzenBis"
                    />
                  </clr-input-container>
                </clr-dg-cell>
                <clr-dg-cell>
                  <clr-checkbox-container>
                    <clr-checkbox-wrapper>
                      <input
                        type="checkbox"
                        clrCheckbox
                        (change)="checkboxchange(item)"
                        [value]="item.UmsetzungErfolgt"
                        name="UmsetzungErfolgt"
                        id="cb{{ item.Nr }}"
                      />
                      <label for="cb{{ item.Nr }}">erfolgt</label>
                    </clr-checkbox-wrapper>
                  </clr-checkbox-container>
                </clr-dg-cell>
                <clr-dg-cell>{{ item.NameUnterschrift }}</clr-dg-cell>
                <clr-dg-cell>{{ item.SchadensmassNachMassnahme }}</clr-dg-cell>
                <clr-dg-cell>{{
                  item.EintrittwahrscheinlichkeitNachMassnahme
                }}</clr-dg-cell>
                <clr-dg-cell [style]="getRisikoNachMassnahme(item).style">{{
                  getRisikoNachMassnahme(item).text
                }}</clr-dg-cell>
              </clr-dg-row>
            </clr-datagrid>
          </clr-tab-content>
        </clr-tab>
        <clr-tab>
          <button clrTabLink>Prüfung</button>
          <clr-tab-content *clrIfActive>
            <table class="table table-compact">
              <thead>
                <tr>
                  <th>Prüftiefe (Prüfart)</th>
                  <th>RCD Test</th>
                  <th>
                    RCD Prüfung und Prüfung der Schutz- und
                    Sicherheitseinrichtungen
                  </th>
                  <th>
                    Sichtprüfung inkl.Thermografie und Prüfung der Schutz- und
                    Sicherheitseinrichtungen
                  </th>
                  <th>Detailprüfung inkl. Abschaltung (Min)</th>
                  <th>Detailprüfung inkl. Reduktionsfaktor</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of pruefItems">
                  <tr>
                    <td>{{ item.Prueftiefe }}</td>
                    <td>{{ item.RCDTest }}</td>
                    <td>{{ item.RCDPruefung }}</td>
                    <td>{{ item.Sichtpruefung }}</td>
                    <td>{{ item.Detailpruefung }}</td>
                    <td>{{ item.DetailpruefungReduktionsfaktor }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </clr-tab-content>
        </clr-tab>
      </ng-container>
      <!-- <clr-tab>
        <app-risks-evaluations />
      </clr-tab> -->
    </clr-tabs>
  </ng-container>
  <!-- ------------------------------- -->
  <br />
  <div class="appitem"></div>
  <br />
</ng-container>
<p></p>
<!-- </form> -->

<!-- popup-windows -->

<!-- Dialog Help -->
<clr-modal [(clrModalOpen)]="onHelper">
  <h3 class="modal-title">
    <cds-icon shape="tag"></cds-icon>
    {{ title }}
  </h3>
  <div class="modal-body" [innerHTML]="message"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onHelper = false">
      Ok
    </button>
  </div>
</clr-modal>

<!-- Dialog Help -->
<clr-modal [(clrModalOpen)]="pdfView" clrModalSize="lg">
  <h3 class="modal-title">
    <cds-icon shape="pdf-file" size="md"></cds-icon>
    {{ title }}
  </h3>

  <div class="modal-body">
    <!-- [useBrowserLocale]="true" -->
    <ngx-extended-pdf-viewer
      *ngIf="test; else elsetest"
      [base64Src]="pdfBase64Src"
      [height]="'60vh'"
      [showBorders]="true"
      [showHandToolButton]="true"
      [handTool]="true"
      [filenameForDownload]="'FILENAME.pdf'"
    >
    </ngx-extended-pdf-viewer>

    <ng-template #elsetest>
      <div class="pdf-viewer-template">
        <span class="spinner spinner-inline"></span>
        <!-- <span *ngIf="document?.Filesize">
          {{ document.Filesize | filesize }}
          | {{ document.Filename }}
        </span> -->
      </div>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="pdfView = false">
      Ok
    </button>
  </div>
</clr-modal>
