<!-- <button clrTabLink>Fotos</button> -->

<!-- <form clrForm [formGroup]="formMobile"> -->
<br />
<p>
  <button
    class="btn btn-outline"
    (click)="onAddFileClick()"
    [disabled]="!online || addBtnDisabled"
  >
    <cds-icon shape="plus-circle"></cds-icon> hinzufügen
  </button>
  {{ infoTxt }}
</p>
<div>
  <table class="table">
    <thead>
      <tr>
        <th class="left">Name</th>
        <th>Vorschau</th>
        <th>Optionen</th>
      </tr>
    </thead>
    <tbody>
      <!-- <tr *ngFor="let img of images$; let i = index"> -->
      <tr *ngFor="let img of cabinet?.Images; let i = index">
        <td class="left">
          <a [routerLink]="[]" role="button" (click)="showImage(img)">
            {{ img.Name }}
          </a>
        </td>
        <td>
          <img
            [src]="getThumbImage(img)"
            width="40px"
            alt="Vorschau"
            role="button"
            (click)="showImage(img)"
          />
        </td>
        <td>
          <!-- &nbsp;<button
            class="btn btn-icon btn-primary"
            title="speichern"
            (click)="onUpdateFile(img)"
          >
            <cds-icon shape="sync"></cds-icon>
          </button> -->
          &nbsp;<button
            class="btn btn-icon btn-primary"
            title="löschen"
            (click)="onDeleteFile(img)"
          >
            <cds-icon shape="trash" />
          </button>
          &nbsp;<button
            class="btn btn-icon btn-primary"
            title="als Vorschau"
            [disabled]="img.AsPreview"
            (click)="onSetPreviewImg(img)"
          >
            <cds-icon shape="image" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- </form> -->

<!-- popup-windows -->

<app-dialog-add-image
  #imgDialog
  *ngIf="dialogAddFile"
  (isDialogOpen)="onDialogAddFileClosing()"
  (uploadFiles)="onUpdateFiles($event)"
  (initializied)="setDialog($event)"
  [maxCount]="getDialogImgCount()"
></app-dialog-add-image>

<app-dialog-show-image
  #imgDialog
  [images]="getImages()"
  (modalOpen)="onDialogClosing($event)"
  [slideIndex]="imgIndex"
  *ngIf="dialogViewImg"
/>
<!-- <app-dialog-import-cabinet
  *ngIf="dialogAddFile"
  (basic)="onDialogAddFileClick()"
  [template$]="template$"
></app-dialog-import-cabinet> -->
