import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Cabinet } from 'src/app/models/Cabinet';
import { Template } from 'src/app/models/Template';
import { Evaluation } from 'src/app/models/Evaluation';
import { BaseImage } from 'src/app/models/Image';
import { selectProfilRoles } from '../../users/+profil/profil.selectors';
import { RiskTabEvaluationComponent } from '../risks-tab-evaluation/risks-tab-evaluation.component';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import { RiskTabEvaluationImagesComponent } from '../risks-tab-images-evaluation/risks-tab-images-evaluation.component';

import {
  ClrBreadcrumbModel,
  ClrBreadcrumbService,
} from '@porscheinformatik/clr-addons';

@Component({
  selector: 'app-risks-evaluation-edition',
  templateUrl: './risks-evaluation-edition.component.html',
  styleUrls: ['./risks-evaluation-edition.component.css'],
})
export class RisksEditEvaluationComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('pruefungTab') tabPruefung = {} as RiskTabEvaluationComponent;
  @ViewChild('gefaehrdungTab') tabGefaehrdung =
    {} as RiskTabEvaluationComponent;
  @ViewChild('fotosTab') tabFotos = {} as RiskTabEvaluationImagesComponent;
  evaluation$ = undefined as unknown as Observable<Evaluation> | null;
  cabinet$ = undefined as unknown as Observable<Cabinet> | null;
  // template$ = new Observable<Template>();
  cabinet = null as Cabinet | null;
  template = undefined as unknown as Template;
  evaluation = undefined as unknown as Evaluation;
  onHelper = false;
  onNewEvaluation = false;
  images = false;
  imageArray = [] as BaseImage[];
  text = '';
  imgViewSrc = undefined as string | undefined;
  imgIndex = 0;
  title = '';
  message = '';
  isService$ = this.store.select(selectProfilRoles(['service']));
  isManager$ = this.store.select(selectProfilRoles(['admin']));
  online = this.service.isOnline$;

  formBuilder = inject(FormBuilder);

  formEvaName = this.formBuilder.group({
    evaluationName: new FormControl(this.evaluation?.Name, {
      nonNullable: false,
    }),
  });

  formMobile = this.formBuilder.group({
    Image: this.formBuilder.group({
      Path: new FormControl('', { nonNullable: true }),
      Name: new FormControl('', { nonNullable: true }),
      id: new FormControl('', { nonNullable: true }),
      cid: new FormControl('', { nonNullable: true }),
    }),
    Images: this.formBuilder.array([]),
  });

  constructor(
    private store: Store,
    private service: AssessmentStoreService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: ClrBreadcrumbService,
  ) {}

  ngOnInit(): void {
    const breadcrumb1: ClrBreadcrumbModel = { label: 'Home', url: '/' };
    const breadcrumb2: ClrBreadcrumbModel = {
      label: 'Gefährdungsbeurteilung',
      url: '/risks',
    };
    this.breadcrumbService.updateBreadcrumb([breadcrumb1, breadcrumb2]);

    this.route.params.subscribe((params) => {
      this.evaluation$ = this.service.loadEvaluation(params['id']);
      this.evaluation$.subscribe((evaluation) => {
        if (evaluation) {
          this.evaluation = evaluation;
          this.cabinet = evaluation.Cabinet;

          const breadcrumb1: ClrBreadcrumbModel = { label: 'Home', url: '/' };
          const breadcrumb2: ClrBreadcrumbModel = {
            label: 'Gefährdungsbeurteilung',
            url: '/risks',
          };
          const breadcrumb3: ClrBreadcrumbModel = {
            label: 'Anlage',
            url: `/risks/review/${this.cabinet?._id}`,
          };
          const breadcrumb4: ClrBreadcrumbModel = { label: 'Bearbeitung' };
          this.breadcrumbService.updateBreadcrumb([
            breadcrumb1,
            breadcrumb2,
            breadcrumb3,
            breadcrumb4,
          ]);

          const form = this.formEvaName.get('evaluationName');
          console.log(`FORM (Evaluation.Name[${evaluation.Name}]): `, form);
          form?.patchValue(evaluation.Name);
        } else {
          console.warn('ngOnInit() evaluation:', evaluation);
        }
      });
    });
  }

  imgForwards() {
    if (this.evaluation?.Images) {
      if (this.imgIndex < this.evaluation.Images.length - 1) {
        this.imgIndex += 1;
      } else {
        this.imgIndex = 0;
      }
      this.setDialogImgSrc();
    }
  }

  imgBackwards() {
    if (this.evaluation?.Images) {
      if (this.imgIndex > 0) {
        this.imgIndex -= 1;
      } else {
        this.imgIndex = this.evaluation.Images.length - 1;
      }
      this.setDialogImgSrc();
    }
  }

  showImages() {
    if (!this.images && this.evaluation?.Images) {
      this.service
        // .loadEvaluationImage(
        //   this.evaluation._id,
        .loadImage(this.evaluation.Images[this.imgIndex]._id)
        .subscribe((result) => {
          this.imgViewSrc = `data:${
            this.evaluation?.Images
              ? this.evaluation.Images[this.imgIndex].MIME
              : ''
          };base64,${result?.data}`;
          this.text = result?.data?.name;
          this.images = true;
        });
    }
  }

  setDialogImgSrc() {
    if (this.evaluation?.Images) {
      this.service
        // .loadEvaluationImage(
        //   this.evaluation._id,
        .loadImage(this.evaluation.Images[this.imgIndex]._id)
        .subscribe((result) => {
          this.imgViewSrc = `data:${
            this.evaluation?.Images
              ? this.evaluation.Images[this.imgIndex].MIME
              : ''
          };base64,${result?.data}`;
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      if (changes['cabinet$']) {
        this.cabinet$?.subscribe((cabinet) => {
          this.cabinet = cabinet;
        });
      }
      if (changes['evaluation$']) {
        this.tabFotos.evaluation = this.evaluation;
        this.evaluation$?.subscribe((evaluation) => {
          if (evaluation) {
            this.evaluation = evaluation;
            this.tabFotos.evaluation = this.evaluation;
            this.imageArray = [];
            evaluation?.Images?.forEach((img) => this.imageArray.push(img));
            this.cabinet = evaluation?.Cabinet;
            this.cabinet$ = this.service.loadCabinet(evaluation.Cabinet._id);
          } else {
            console.error('ngOnInit() evaluation:', evaluation);
          }
        });
      }
    } catch (err) {
      console.error('FEHLER : ', err);
    }
  }

  ngOnDestroy(): void {
    this.uploadSub$.unsubscribe();
  }

  file: File | null = null;
  excelFile: File | null = null;
  uploadProgress = 0;
  uploadSub$ = new Subscription();

  imgSet(event: any) {
    this.evaluation$ = this.service.loadEvaluation(event._id);
    this.evaluation$?.subscribe((evaluation) => {
      this.evaluation = evaluation;
    });
  }

  refresh(newEvaluation: any) {
    if (newEvaluation) {
      this.evaluation = newEvaluation;
    }
  }

  initPruefung($event: any) {
    try {
      this.tabPruefung = $event as RiskTabEvaluationComponent;
    } catch (err) {
      console.error(err);
    }
  }

  initGefaehrdung($event: any) {
    try {
      this.tabGefaehrdung = $event as RiskTabEvaluationComponent;
    } catch (err) {
      console.error(err);
    }
  }

  initFiles($event: any) {
    try {
      this.tabFotos = $event as RiskTabEvaluationImagesComponent;
      this.tabFotos.evaluation = this.evaluation;
    } catch (err) {
      console.error(err);
    }
  }

  initAuswertung($event: any) {
    // try {
    //   this.tabAuswertung = $event as RiskTabResultsComponent;
    //   if (this.evaluation) {
    //     this.tabAuswertung.setEvaluation(this.evaluation);
    //   }
    // } catch (err) {
    //   console.error(err);
    // }
  }

  onHelperClick(title: string, message: string | undefined) {
    this.title = title || '';
    this.message = message || '';
    this.onHelper = true;
    return false;
  }

  onFileSelected(event: any) {
    this.file = event.target.files[0];
  }

  newEvaluationClick() {
    this.evaluation.PDFResult.requested = true;
    this.service.patchEvaluation(this.evaluation).subscribe((evaluation) => {
      if (evaluation) {
        this.evaluation = evaluation;
        this.cabinet = evaluation.Cabinet;
        this.router.navigate(['/risks/review', this.cabinet?._id], {
          relativeTo: this.route,
        });
        // this.router.navigate(['review', cabinet._id], {
        //   relativeTo: this.activatedRoute,
        // });
      } else {
        console.warn('onSubmitEvaName() evaluation:', evaluation);
      }
    });
  }

  onSubmitEvaName(event: any) {
    const formEvaName = this.formEvaName.getRawValue();
    const name = formEvaName.evaluationName;
    if (name) {
      this.evaluation.Name = name;
      this.service.patchEvaluation(this.evaluation).subscribe((evaluation) => {
        if (evaluation) {
          this.evaluation = evaluation;
          this.cabinet = evaluation.Cabinet;
          this.formEvaName.get('evaluationName')?.setValue(evaluation.Name);
        } else {
          console.warn('onSubmitEvaName() evaluation:', evaluation);
        }
      });
    }
  }

  formDisabled(): boolean {
    const disabled =
      this.evaluation?.PDFResult?.requested !== undefined &&
      this.evaluation.PDFResult.requested;
    return disabled;
  }

  imgTabDisabled(): boolean {
    return this.evaluation?._id?.startsWith('offline') ? true : false;
  }

  hasResult(): boolean {
    return this.evaluation?.PDFResult?.File != undefined;
  }

  resetUpload() {
    this.uploadProgress = 0;
    this.uploadSub$.unsubscribe();
  }
}
